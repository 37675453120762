/* styles.css */

.slide-enter {
    transform: translateY(-100%);
    opacity: 0;
}
.slide-enter.slide-enter-active {
    transform: translateY(0);
    opacity: 1;
    transition: all 300ms ease-in-out;
}

.slide-exit {
    transform: translateY(0);
    opacity: 1;
}
.slide-exit.slide-exit-active {
    transform: translateY(100%);
    opacity: 0;
    transition: all 300ms ease-in-out;
}

.message-bubble {
    /* display: inline-block; */
    max-width: 90%;
    min-width: 50px; /* Added this line */
    /* word-wrap: break-word; */
    border-radius: 0.5rem;
    /* white-space: pre-wrap; Added to preserve whitespace */
}

.message-bubble p:last-child {
    margin-bottom: 0; /* Remove extra bottom margin from the last paragraph */
}

.feedback-button {
    position: fixed;
    bottom: 20px; /* Distance from the bottom */
    left: 50%;    /* Position the left edge at 50% of the viewport width */
    transform: translateX(-50%); /* Shift left by 50% of the button's width to center */
    z-index: 1000;
    padding: 0.5rem 1rem; /* Smaller padding for a smaller button */
    font-size: 0.875rem; /* Smaller font size */
    border-radius: 25px; /* Rounded corners */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: subtle shadow */
    /* Optional: Transition for hover effects */
    transition: background-color 0.3s, transform 0.3s;
    
    /* Optional: Ensure the button doesn't exceed viewport width on very small screens */
    max-width: 90%;
    width: auto;
  }
  
  .feedback-button:hover {
    background-color: #0b5ed7; /* Darker shade on hover */
    transform: translateX(-50%) translateY(-2px); /* Maintain horizontal centering and lift on hover */
    text-decoration: none; /* Remove underline */
    color: #fff; /* Ensure text remains white */
  }
  
  /* Responsive Adjustments (Optional) */
  @media (max-width: 576px) {
    .feedback-button {
      bottom: 15px;
      padding: 0.4rem 0.8rem;
      font-size: 0.75rem;
    }
  }
  
/* Updated styles for marquee slider */

.marquee-wrapper {
  overflow: hidden;
  width: 100%;
}

.marquee-content {
  display: inline-flex;
  gap: 1rem;
  white-space: nowrap;
  /* Adjust the duration as desired */
  animation: marquee 85s linear infinite;
}

/* Keyframes: translate left by 50% since we duplicate the content */
@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

/* Keep your existing styles for individual items */
.marquee-content > div {
  padding: 8px 12px;
  border-radius: 20px;
  background-color: #e9ecef;
  font-size: 0.9rem;
  cursor: pointer;
  white-space: nowrap;
}

.marquee-content > div:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

  

  .scroll-container {
    display: flex;
    animation: scroll 20s linear infinite;
    gap: 2rem;
    /* padding: 0 1rem; */
}

@keyframes scroll {
    0% { transform: translateX(0); }
    100% { transform: translateX(calc(-120px * 6 - 4rem * 6)); }
}